import clsx from "clsx";
import { ReactNode } from "react";

type HeadingType = "h1" | "h2" | "h3";

type MainHeadingProps = {
  children: ReactNode;
  className?: string;
  headingType?: HeadingType;
} & React.HTMLAttributes<HTMLHeadingElement>;

const MainHeading = ({
  children,
  className,
  headingType = "h1",
  ...rest
}: MainHeadingProps) => {
  const Element = headingType;

  return (
    <Element
      className={clsx(
        "MainHeading",
        "text-balance font-spatial text-3xl/[1] font-extrabold md:text-4xl/[1] lg:max-w-[30ch] lg:text-5xl/[0.9] 2xl:text-6xl/[0.9] print:!text-xl",
        className
      )}
      {...rest}
    >
      {children}
    </Element>
  );
};

export default MainHeading;
