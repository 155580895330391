import { ChevronDownIcon } from "@heroicons/react/24/outline";
import * as Accordion from "@radix-ui/react-accordion";
import clsx from "clsx";
import { useState } from "react";

const FaqAccordionList = ({ faqs }) => {
  const [openItems, setOpenItems] = useState([]);

  const handleChange = (value) => {
    setOpenItems(value);
  };

  return (
    <Accordion.Root
      type="multiple"
      value={openItems}
      onValueChange={handleChange}
    >
      {faqs.map((faq) => (
        <_AccordionItem
          key={faq.key}
          id={faq.key}
          title={faq.question}
          isOpen={openItems.includes(faq.key)}
        >
          <div
            className="body-text-sm"
            dangerouslySetInnerHTML={{ __html: faq.answer }}
          />
        </_AccordionItem>
      ))}
    </Accordion.Root>
  );
};

const _AccordionItem = ({ id, title, children, isOpen }) => {
  return (
    <Accordion.Item value={id} className="border-b pb-4">
      <Accordion.Header>
        <Accordion.Trigger className="flex w-full justify-between gap-2 pt-4 transition ease-out hover:opacity-75">
          <span className="text-left text-base">{title}</span>
          <ChevronDownIcon
            className={clsx(
              "block h-5 w-5 flex-shrink-0 transition duration-300 ease-out",
              {
                "rotate-180": isOpen,
              }
            )}
          />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className={clsx("pt-4")}>{children}</Accordion.Content>
    </Accordion.Item>
  );
};

export default FaqAccordionList;
