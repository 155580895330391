import useLineClampCheck from "@/src/hooks/useLineClampCheck";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useMediaQuery } from "../hooks/useMediaQuery";

type ExpandableSummaryProps = {
  summary: string;
  className?: string;
};

const ExpandableSummary = ({ summary }: ExpandableSummaryProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const isClamped = useLineClampCheck(ref, isMobile ? 2 : 5);

  return (
    <div className={clsx("body-text-sm xl:body-text relative max-w-prose")}>
      <div
        ref={ref}
        className={clsx({ "line-clamp-2 md:line-clamp-5": !isExpanded })}
        dangerouslySetInnerHTML={{ __html: summary }}
      />

      {!isExpanded && isClamped && (
        <button
          data-nosnippet // to exclude the See more from being shown in Google Search results
          onClick={() => setIsExpanded(true)}
          className={clsx(
            "underline decoration-zinc-300 decoration-1 underline-offset-4 transition ease-out hover:opacity-75"
          )}
        >
          See more
        </button>
      )}
    </div>
  );
};

export default ExpandableSummary;
