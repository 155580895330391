import RailCarousel from "@/components/RailCarousel";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserDietaryRequirements from "@/hooks/useCurrentUserDietaryRequirements";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useInView } from "react-intersection-observer";
import useSWRImmutable from "swr/immutable";

const Rail = observer(
  ({
    rail,
    hitsPerPage = 48,
    shuffleSeed = -1,
    entriesToShow = 24,
    applyUserDietaryRequirements = true,
    dietaryRequirements = [], // this is only used in debugging /rails/[slug]
    debug = false,
    showHeading = true,
    className = "",
    carouselClassName = undefined,
  }) => {
    // Fetch the current user
    const { isFetching } = useCurrentUser();

    const { plan: userPlan } = useSubscriptionPermission();

    // If the user is logged in and has dietary requirements, we need to add them to the facet filters
    const { dietaryRequirementTitles } = useCurrentUserDietaryRequirements();

    // Determine whether to show free recipes or not
    const free = userPlan === 2 ? false : true;

    // Create the query string options
    const queryStringOptions = {
      free,
      dietaryRequirement: dietaryRequirementTitles?.[0] || undefined,
      shuffleSeed,
      entriesToShow,
      hitsPerPage,
    };

    // Remove unused properties and create the query string
    const queryString = new URLSearchParams(
      Object.entries(queryStringOptions).filter(
        ([, value]) => value !== undefined
      )
    ).toString();

    // Setup the in-view hook
    const { ref, inView } = useInView({
      threshold: 0,
      triggerOnce: true,
      rootMargin: "0px 0px 200px 0px",
    });

    // Should load the rail if it's in view and if the auth check has completed
    const shouldLoadEntries = !isFetching && inView;

    // Use useSWR to fetch the rail from /api/rails/{id}/entries with the query string
    // We use useSWRImmutable just because the rails don't change often
    const { data, isLoading } = useSWRImmutable(
      shouldLoadEntries ? `/api/rails/${rail.id}/entries?${queryString}` : null
    );

    const entries = data?.hits;

    // We should show the skeleton loader if we're still loading the rail or fetching the user
    const isRailLoading = isLoading || isFetching;

    return (
      <div className={clsx("Rail", className)} ref={ref}>
        {isRailLoading ? (
          <RailCarousel
            key={shuffleSeed}
            showHeading={showHeading}
            heading={`${rail.frontendTitle}`}
            summary={rail.summary}
            className={carouselClassName}
          />
        ) : (
          <RailCarousel
            key={shuffleSeed}
            showHeading={showHeading}
            summary={rail.summary}
            heading={`${rail.frontendTitle}`}
            entries={entries}
            className={carouselClassName}
          />
        )}

        {debug && (
          <div className={clsx("p-12")}>
            <pre
              className={clsx("font-mono overflow-hidden whitespace-pre-wrap")}
            >
              {JSON.stringify(
                {
                  entryIds: entries?.map((entry) => entry.id),
                },
                null,
                2
              )}
            </pre>
          </div>
        )}
      </div>
    );
  }
);

export default Rail;
