import useSWR from "swr";

const fetcher = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    });
    return response.redirected;
  } catch {
    return true;
  }
};

export const useDetectAdBlock = (): boolean => {
  const { data: adBlockDetected } = useSWR("adblock-detection", () =>
    fetcher("https://www3.doubleclick.net")
  );

  return adBlockDetected ?? false;
};

export default useDetectAdBlock;
