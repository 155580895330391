import { RefObject, useEffect, useState } from "react";

const useLineClampCheck = (ref: RefObject<HTMLElement>, lines = 2) => {
  const [isClamped, setIsClamped] = useState(false);

  const checkClamping = () => {
    if (ref.current) {
      const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);
      const maxHeight = lineHeight * lines;
      setIsClamped(ref.current.scrollHeight > maxHeight);
    }
  };

  useEffect(() => {
    checkClamping();
    window.addEventListener("resize", checkClamping);
    return () => window.removeEventListener("resize", checkClamping);
  }, [ref, lines]);

  return isClamped;
};

export default useLineClampCheck;
