import clsx from "clsx";
import Image from "next/image";
import { memo } from "react";

const BackgroundNeon = memo(() => {
  return (
    <div>
      <Image
        src={`/images/neon-gradient.png`}
        alt=""
        layout="fill"
        objectFit="cover"
        role="presentation"
        className={clsx(
          "pointer-events-none absolute inset-0 block h-full w-full"
        )}
      />
      <Image
        src={`/images/neon-gradient.png`}
        alt=""
        layout="fill"
        objectFit="cover"
        role="presentation"
        className={clsx(
          "pointer-events-none absolute inset-0 block h-full w-full rotate-180",
          "animate-fade-in-out"
        )}
      />
    </div>
  );
});

BackgroundNeon.displayName = "BackgroundNeon";

export default BackgroundNeon;
