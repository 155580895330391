import clsx from "clsx";

const AdBlockerMessage = ({}) => {
  return (
    <div
      className={clsx(
        "mx-auto flex max-w-screen-sm flex-col items-center justify-center rounded-2xl border border-dashed border-zinc-300 p-6 text-center lg:p-12"
      )}
    >
      <div className={clsx("heading-xs--spatial mb-2")}>
        It looks like you have an ad blocker enabled
      </div>
      <div className={clsx("font-body text-sm text-zinc-500")}>
        Please consider disabling your ad blocker to support us.
      </div>
    </div>
  );
};

export default AdBlockerMessage;
