import useCurrentUser from "@/hooks/useCurrentUser";

const useFaqs = (
  globalFaqs = [],
  curatedFaqs = [],
  filter,
  useGlobalFaqsFallback = true
) => {
  const { currentUser } = useCurrentUser();
  const plan = currentUser?.plan ?? null;

  const planMapping = {
    null: "guest",
    2: "premium",
    3: "freeMember",
    4: "freeMember",
  };

  const userType = planMapping[plan];

  const filteredGlobalFaqs = globalFaqs.filter(
    (faq) =>
      faq.showForUserPlan.includes(userType) && faq.showOnPages.includes(filter)
  );

  const flattenedCuratedFaqs = curatedFaqs
    ? curatedFaqs
        .map((item) => {
          if (item.faq) {
            if (item.faq[0].showForUserPlan.includes(userType)) {
              return {
                key: item.key,
                question: item.faq[0].question,
                answer: item.faq[0].answer,
              };
            }
          } else {
            return item;
          }
        })
        .filter((item) => item)
    : [];

  if (flattenedCuratedFaqs.length) {
    return flattenedCuratedFaqs;
  } else if (useGlobalFaqsFallback) {
    return filteredGlobalFaqs;
  } else {
    return [];
  }
};

export default useFaqs;
